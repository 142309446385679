import React from "react";
import CategoriesMainSection from "../../components/CategoriesCompnents/CategoriesMainSection";

const Categories = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
    <CategoriesMainSection/>
    </div>
  );
};

export default Categories;
