export const validateHealthCardForm = (initialState, category, bullent_points, health_card_image, setErr) => {
  if (!initialState.card_name.trim().length) {
    setErr({ global: 'Invalid Card Name' });
    return false;
  }
  if (initialState.card_type === 'Select Type') {
    setErr({ global: 'Please Select Type' });
    return false;
  }
  if (!initialState.available_offer.trim().length) {
    setErr({ global: 'Invalid Available Offer' });
    return false;
  }
  if (!category?.length) {
    setErr({ global: 'Please Select At Least One Category' });
    return false;
  }
  if (isNaN(Number(initialState.price)) ||Number(initialState.price) < 0) {
    setErr({ global: 'Invalid Price' });
    return false;
  }
  if (isNaN(Number(initialState.recharge_amount)) || Number(initialState.recharge_amount) < 0) {
    setErr({ global: 'Invalid Recharge Amount' });
    return false;
  }
  if (!initialState.expired_date) {
    setErr({ global: 'Invalid Expire Date' });
    return false;
  }
  // if (Number(initialState.free_offers) < 0) {
  //   setErr({ global: 'Invalid Free Offers' });
  //   return false;
  // }
  // if (Number(initialState.discount_used) < 0) {
  //   setErr({ global: 'Invalid Discount Count' });
  //   return false;
  // }
  if (!bullent_points.length) {
    setErr({ global: 'Please Add At Least One Point' });
    return false;
  }
  if (!health_card_image) {
    setErr({ global: 'Please Select Image' });
    return false;
  }
  return true;
}