import React, { useCallback, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  AddButton,
  DateInput,
  ImageInput,
  NumberInput,
  SelectInput,
  ShowImage,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { getCategories } from "../../utils/CategoriesUtils/CategoriesUtils";
import { addHealthCard } from "../../utils/HealthCardsUtils/HealthCardsUtils";
import { validateHealthCardForm } from "../../utils/HealthCardsUtils/validateHealthCardForm";
import { IoSearchOutline } from "react-icons/io5";

const HealthCardsModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setDatasFn = ()=> {},
}) => {
  const initialState = {
    card_name: editData?.card_name || "",
    price: editData?.price || 0,
    recharge_amount: editData?.recharge_amount || 0,
    card_type: editData?.card_type || "Select Type",
    category: editData?.category?.type || "Select Category",
    available_offer: editData?.available_offer || "",
    doctor_using_credit: editData?.doctor_using_credit || 0,
    deductionRate: editData?.deductionRate || 0,
    additionRate: editData?.additionRate || 0,
    credit_score: editData?.credit_score || 0,
    doctor_reduce_credit: editData?.doctor_reduce_credit || 0,
    lab_using_credit: editData?.lab_using_credit || 0,
    lab_reduce_credit: editData?.lab_reduce_credit || 0,
    free_offers: editData?.free_offers || 0,
    lab_free_opd: editData?.lab_free_opd || 0,
    discount_percentage: editData?.discount_percentage || 0,
    lab_discount: editData?.lab_discount || 0,
    discount_after_5_offer: editData?.discount_after_5_offer || 0,
    discount_used: editData?.discount_used || 0,
    expired_date: editData?.expired_date || "",
  };
  const [hide, setHide] = useState(editData?.hide || false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(editData?.category || []);
  const [selected, setSelected] = useState(null);  
  const setCategoriesFn = useCallback(() => {
    getCategories()
      .then((res) => {
        setCategories(res?.data?.data);
      })
      .catch((err) => {
        setCategories([]);
      });
  }, []);
  useEffect(() => {
    setCategoriesFn();
  }, [setCategoriesFn]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
      const filteredData = categories?.filter((category) => {
        const nameMatch = category?.type
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        return nameMatch;
      });
      setFilteredData(filteredData);
    }, [categories, searchQuery]);
  const handleCatgories = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = category.find(
        (data) => data?._id === item?._id
      );
      if (exist) return;
      setSelected(item);
      setCategory([...category, item]);
    } else if (action === "remove") {
      setCategory(category.filter((_, index) => index !== idx));
    }
  };
  const [bullent_points, setBulletPoints] = useState(editData?.bullent_points || []);
  const bulletPointInitialState = { point: "" };
  const [bulletPointFormData, handleBulletPointFormData, clearBulletPointFormData] =
    useForm(bulletPointInitialState);
  const handleBulletPoints = (action, idx = 0) => {
    if (action === "add") {
      if (
        !bulletPointFormData.point.trim().length 
      ) {
        return setErr({ global: "Please Enter Point" });
      } else {
        setBulletPoints([...bullent_points, bulletPointFormData.point]);
        clearBulletPointFormData();
      }
    } else if (action === "remove") {
      setBulletPoints(bullent_points.filter((_, index) => index !== idx));
    }
  };

  const [health_card_image , setCardImage] = useState(editData?.health_card_image  || null);
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = () => {
    if (!validateHealthCardForm(formData,category,bullent_points, health_card_image, setErr)) return;
      setErr({ global: "" });
      setLoading(true);
      addHealthCard(
        formData,
        category,
        bullent_points,
        health_card_image,
        hide,
        editData?._id
      )
        .then((res) => {
          setDatasFn();
          clearForm();
          setEditData(null);
          setOpen(false);
          toast(`Health Card ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          toast(`Health Card ${editData ? "Edit Failed" : "Adde Failed"}`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Health Card"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"card_name"}
            name={"Card Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <SelectInput
            data={formData}
            formName={"card_type"}
            handleInput={handleFormData}
            items={[
              "Select Type",
              "Free",
              "Platinum",
              "Gold",
              "Silver",
              "Senior citizen",
              "Women special",
            ]}
            setError={setErr}
            name="Type"
          />
          <TextInput
            formName={"available_offer"}
            name={"Available Offer"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {category?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {category?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <span>{item?.type}</span>
                </div>
                <button
                  onClick={() => {
                    handleCatgories("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of General Categories"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of General Categories"}
          </label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <div
                key={item._id}
                onClick={() => {
                  handleCatgories("add", null, item);
                }}
                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                  selected?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-white`
                }`}
              >
                {`${item?.type}`}
              </div>
            ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Categories Found!
            </div>
          )}
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"price"}
            name={"Price"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"recharge_amount"}
            name={"Recharge Amount"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <DateInput
            formName={"expired_date"}
            name={"Expire Date"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-start gap-5">
          <NumberInput
            hide={true}
            formName={"free_offers"}
            name={"Free Offers Count"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"discount_percentage"}
            name={"Discount Percentage"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"discount_used"}
            name={"Discount Count"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-start gap-5">
          <NumberInput
            hide={true}
            formName={"lab_free_opd"}
            name={"Lab Free Offers Count"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"lab_discount"}
            name={"Lab Discount Percentage"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-start gap-5">
          <NumberInput
            hide={true}
            formName={"credit_score"}
            name={"Credit Points"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"deductionRate"}
            name={"Deduction Rate (Per 100 Points)"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"additionRate"}
            name={"Addition Rate (Per 100 Rs)"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-start gap-5">
          <NumberInput
            hide={true}
            formName={"doctor_using_credit"}
            name={"Consultancy Usable Points"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"doctor_reduce_credit"}
            name={"Consultancy Reduce Points"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-start gap-5">
          <NumberInput
            hide={true}
            formName={"lab_using_credit"}
            name={"Lab Usable Points"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"lab_reduce_credit"}
            name={"Lab Reduce Points"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {bullent_points?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {bullent_points?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleBulletPoints("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={bulletPointFormData}
            handleInput={handleBulletPointFormData}
            name={"Bullet Ponits"}
            formName={"point"}
            setError={setErr}
          />
          <AddButton handleAdd={handleBulletPoints} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={health_card_image} setImage={setCardImage} />
          <ImageInput imageName={"Card Image"} setImage={setCardImage} />
        </div>
        <div className="flex w-full gap-2">
          <span className="text-shadow-black text-white font-semibold">Hide Card:</span>
          <input
            type="checkbox"
            checked={hide}
            onChange={(e) => setHide(e.target.checked)}
          />
        </div>

        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthCardsModal;
