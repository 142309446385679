import React, { useCallback, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  DateInput,
  RadioInput,
} from "../Common/FormComponents";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getBranches } from "../../utils/branchesUtils/branchesUtils";
const typeOptions = [
  { label: "Platinum", value: "Platinum" },
  { label: "Gold", value: "Gold" },
  { label: "Silver", value: "Silver" },
];
const HealthCardsFilter = ({
  setOpen,
  selected,
  setSelected,
}) => {
  const [err, setErr] = useState({ global: "" });
  const initialState = {
    joining_date: selected?.joining_date || null,
    expiry_date: selected?.expiry_date || null,
    card_type: selected?.card_type || null,
  };
  const [datas, setDatas] = useState([ ]);
    const setDatasFn = useCallback(() => {
        getBranches().then(res => setDatas(res.data.data)).catch(error => console.error(error));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [selectedRegions, setSelectedRegions] = useState(selected?.regions || []);
  const handleSelectedRegions = (action, selected = null, idx = 0) => {
    if (action === "add") {
      if (!selected) return;
      const exist = selectedRegions.find(item => item === selected);
      if (exist) return;
      setSelectedRegions((prev) => [...prev, selected]);
    } else if (action === "remove") {
      setSelectedRegions((prev)=> prev.filter((_, index) => index !== idx));
    }
  };
  const [filerData, handleFilterData, _clearForm, setValues] = useForm(initialState);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSelected({ ...filerData, regions: selectedRegions });
    setOpen(false);
  }
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Health Card Filter"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        {selectedRegions?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {selectedRegions?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleSelectedRegions("remove", null, idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className="lable-and-filed">
            <label htmlFor={"Region"} className="label">
              Region
            </label>
            <div
              className="input-coantiner"
              onClick={() => setErr({ global: "" })}
            >
              <select
                className="select-option"
                name={"Region"}
                onChange={(event) => {
                  handleSelectedRegions("add", event.target.value);
                }}
              >
                <option>{"Select Region"}</option>
                {datas?.map((item) => (
                  <option key={item?._id} value={item?.Branch}>
                    {item?.Branch}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <RadioInput
            data={filerData}
            name="Card Type"
            formName={"card_type"}
            handleInput={handleFilterData}
            setError={setErr}
            hide={true}
            options={typeOptions}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <DateInput
            data={filerData}
            handleInput={handleFilterData}
            formName={"joining_date"}
            setError={setErr}
            name="Joining Date"
          />
          <DateInput
            data={filerData}
            handleInput={handleFilterData}
            formName={"expiry_date"}
            setError={setErr}
            name="Expiry Date"
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          {(filerData?.card_type ||
            filerData?.joining_date || 
            filerData?.expiry_date ||
            selectedRegions.length > 0) && (
            <div className=" flex flex-col w-full gap-0.5 ">
              <button
                className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
                onClick={() => {
                  setSelectedRegions([]);
                  setValues({ card_type: null, joining_date: null, expiry_date: null });
                }}
              >
                Clear
              </button>
            </div>
          )}
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthCardsFilter;
