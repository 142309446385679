import React from "react";
import { useMemo } from "react";
import Table from "../Common/Table/Table";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteHealthCard } from "../../utils/HealthCardsUtils/HealthCardsUtils";

const HealthCardsTable = ({ datas, setEditData, setOpen, setDatasFn }) => {
  const headings = useMemo(
    () => [
      "Image",
      "Name",
      "Offer",
      "Category",
      "Type",
      "Price",
      "Expire Date",
      "Edit",
      "Delete",
    ],
    []
    );
  const delData = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteHealthCard(id).then((result) => {
      setDatasFn()
    }).catch((err) => {
      console.error(err);
    });
  }
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                  <img
                    className="rounded-md h-16 w-16 object-cover"
                    src={data?.health_card_image}
                    alt="health_card_image"
                  />
                </div>
              </div>
            </td>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.card_name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.available_offer}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
              {data?.category?.length > 0 ? (
                data?.category?.map((item, idx) => (
                  <span key={idx}>
                    {" "}
                    {idx + 1} {". "} {item?.type}
                  </span>
                ))
              ) : (
                <span>No Categories !</span>
              )}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.card_type}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.price}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.expired_date}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <CiEdit color="green" />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delData(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default HealthCardsTable;
