import React, { useEffect, useMemo, useState } from "react";
import { getTransactions } from "../../utils/HealthCardsUtils/HealthCardsUtils";
import Table from "../Common/Table/Table";
import { NoDataFound } from "../Common";

const TransactionList = ({ selectedUser }) => {
  const [datas, setDatas] = useState([]);
  useEffect(() => {
      getTransactions(selectedUser?._id)
        .then((res) => {
          setDatas(res?.data?.data);
        })
        .catch((err) => console.error(err));
  }, [selectedUser?._id]);
  const headings = useMemo(
    () => ["Payment-ID", "Order-ID", "Type", "Doctor", "Tests", "Date", "Amount"],
    []
  );
  return datas?.length > 0 ? (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.payment_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.order_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.type}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.doctor_name || "No Doctor !"}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
              {data?.lab_test_name?.length > 0 ? (
                data?.lab_test_name?.map((item, idx) => (
                  <span key={idx}>
                    {" "}
                    {idx + 1} {". "} {item}
                  </span>
                ))
              ) : (
                <span>No Tests !</span>
              )}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {new Date(data?.date).toLocaleString()}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.amount}
            </div>
          </td>
        </tr>
      ))}
    </Table>
  ) : (
    <div className="flex w-full justify-center items-center mt-10">
      <NoDataFound data={"transaction"} />
    </div>
  );
};

export default TransactionList;
