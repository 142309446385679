import React from 'react'
import { useState } from 'react';
import { LuPlusCircle } from "react-icons/lu";
import { BiFilterAlt } from "react-icons/bi";
import { NoDataFound, SearchInput } from '../Common';
import HealthCardsTable from './HealthCardsTable';
import { useCallback } from 'react';
import { useEffect } from 'react';
import HealthCardsModal from './HealthCardsModal';
import { getHealthCards } from '../../utils/HealthCardsUtils/HealthCardsUtils';
import HealthCardsFilter from './HealthCardsFilter';


const HealthCardsMainSection = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [editData, setEditData] = useState(null);
    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState([]);
    const setDatasFn = useCallback(() => {
        getHealthCards()
          .then((res) => setDatas(res.data.data || []))
        .catch((err) => console.log(err));
    }, []);
    useEffect(() => {
        setDatasFn();
    }, [setDatasFn])
    const [filterData, setFileterData] = useState({
      expiry_date: null,
      card_type: null,
    });
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  useEffect(() => {
    const filteredData = datas.filter((item) => {
      if (filterData.card_type && item?.card_type !== filterData.card_type)
        return false;
      
      if (filterData?.expiry_date && item?.expiry_date) {
        const date1 = new Date(
          item?.expiry_date.split("/").reverse().join("/")
        );
        const date2 = new Date(
          filterData?.expiry_date.split("/").reverse().join("/")
        );
        if (date1 > date2) return false;
      }

      return true;
    });
    setFilteredDatas(filteredData);
  }, [datas, filterData]);
  return (
    <>
      {open && (
        <HealthCardsModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setDatasFn={setDatasFn}
        />
      )}
      {openFilter &&
      <HealthCardsFilter
        selected={filterData}
        setOpen={setOpenFilter}
        setSelected={setFileterData}
      />
      }
      <div className=" flex w-full items-center gap-5 md:gap-0">
        <div className="flex w-fit h-fit text-xl font-semibold flex-shrink-0">
          Health Cards
        </div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <div className="flex gap-2">
            <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
              onClick={() => {setOpenFilter(true)}}
            >
              <span>Filter</span>
              <span>
                <BiFilterAlt />
              </span>
            </button>
            <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
              onClick={() => setOpen(true)}
            >
              <span>Card</span>
              <span>
                <LuPlusCircle />
              </span>
            </button>
          </div>
        </div>
        {filteredDatas?.length > 0 ? (
          <HealthCardsTable
            setDatasFn={setDatasFn}
            datas={filteredDatas}
            setEditData={setEditData}
            setOpen={setOpen}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"Health Cards"} />
          </div>
        )}
      </div>
    </>
  );
}

export default HealthCardsMainSection