import React from 'react'
import { useState } from 'react';
import { LuPlusCircle } from "react-icons/lu";
import { NoDataFound, SearchInput } from '../Common';
import { useCallback } from 'react';
import { useEffect } from 'react';
import CategoriesTable from './CategoriesTable';
import CategoriesModal from './CategoriesModal';
import { getCategories } from '../../utils/CategoriesUtils/CategoriesUtils';


const CategoriesMainSection = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [editData, setEditData] = useState(null);
    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState([]);
    const setDatasFn = useCallback(() => {
        getCategories()
          .then((res) => setDatas(res.data.data || []))
        .catch((err) => console.log(err));
    }, []);
    useEffect(() => {
        setDatasFn();
    }, [setDatasFn])
  return (
    <>
      {open && (
        <CategoriesModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setDatasFn={setDatasFn}
        />
      )}
      <div className=" flex w-full items-center gap-5 md:gap-0">
        <div className="flex w-fit h-fit text-xl font-semibold">Categories</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <div className="flex gap-2">
            <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
              onClick={() => setOpen(true)}
            >
              <span>Category</span>
              <span>
                <LuPlusCircle />
              </span>
            </button>
          </div>
        </div>
        {datas?.length > 0 ? (
          <CategoriesTable
            setDatasFn={setDatasFn}
            datas={datas}
            setEditData={setEditData}
            setOpen={setOpen}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"Categories"} />
          </div>
        )}
      </div>
    </>
  );
}

export default CategoriesMainSection