import React from "react";
import HealthCardsProfilesMainSection from "../../components/HealthCardsProfilesComponents/HealthCardsProfilesMainSection";

const HealthCardProfiles = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
      <HealthCardsProfilesMainSection />
    </div>
  );
};

export default HealthCardProfiles;
