import React from "react";
import HealthCardsMainSection from "../../components/HealthCardsComponents/HealthCardsMainSection";

const HealthCards = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
    <HealthCardsMainSection/>
    </div>
  );
};

export default HealthCards;
