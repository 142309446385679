import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound } from "../Common";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { getCardBanner } from "../../utils/AboutUtils/AboutUtils";
import CardBannerModal from "./CardBannerModal";

const CardBanner = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getCardBanner()
      .then((res) => {
        setDatas(res?.data.data || []);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [editData, setEditData] = useState(null);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <CardBannerModal
          setOpen={setOpen}
          setDatasFn={setDatasFn}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Card Banner</span>
        {!datas.length > 0 && (
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            onClick={() => setOpen(true)}
            className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
          >
            <span>Add</span>
          </button>
        )}
      </div>
      {datas?.length > 0 ? (
        <Table headings={["Image", "Edit"]}>
          {datas.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                      <img
                        className="rounded-md h-16 w-16 object-cover"
                        src={data?.banner_card_image}
                        alt="banner_card_image"
                      />
                    </div>
                  </div>
                </td>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    setEditData(data);
                    setOpen(true);
                  }}
                >
                  <CiEdit color="green" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"Card Banner"} />
      )}
    </div>
  );
};

export default CardBanner;
