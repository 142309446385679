import { axiosAuthorized } from "../../api/config"

export const getCategories = async () => {
  try {
    const response = await axiosAuthorized.get(`/gethealthcardcategory`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addCategories = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/healthcardcategory/update_healthcardcategory/${id}`
      : `/healthcardcategory/create_healthcardcategory`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCategory = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/healthcardcategory/delete_healthcardcategory/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}