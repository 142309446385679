import React, { useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import {
  ImageInput,
  RadioInput,
  ShowImage,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import JoditEditor, {Jodit} from "jodit-react";
import { sentSingleRenewal } from "../../utils/HealthCardsUtils/HealthCardsUtils";

const config = {
  readonly: false,
  placeholder: "Message",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const HealthCardsRenewalAlert = ({
  setOpen,
  editData = null,
  setEditData = () => {},
}) => {
  const initialState = {
    card_id: editData?._id,
    notification_type: "",
  };
  const editor = useRef(null);
  const [message, setMessage] = useState("<p></p>");
  const [expiry_image, setImageTemplate] = useState(null);
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.notification_type) return setErr({global:"Select Notification Type"});
    if (!message.replace(/<[^>]*>/g, '').trim().length) return setErr({global:"Invalid Message"});
    setErr({ global: "" });
    setLoading(true);
    sentSingleRenewal(
      formData,
      message,
      expiry_image
    )
      .then((res) => {
        clearForm();
        setEditData(null);
        setOpen(false);
        setImageTemplate(null);
        toast(`Offer/Renewal Sent`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Offer/Renewal Failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Offer/Renew"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <RadioInput
            data={formData}
            name="Notification Type"
            formName={"notification_type"}
            handleInput={handleFormData}
            setError={setErr}
            hide={true}
            options={[
              { label: "Email Notification", value: "email" },
              { label: "SMS Notification", value: "sms" },
            ]}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Message"} className="label">
            {"Message"} <span className="text-red-600">*</span>
          </label>
          <div className="bg-white text-black">
            <JoditEditor
              ref={editor}
              value={message}
              config={config}
              tabIndex={1}
              onBlur={(newContent) =>
                setMessage(
                  newContent.replace(
                    /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                    '<td style="border: 1px solid black;" >'
                  )
                )
              }
            />
          </div>
        </div>
        {formData.notification_type === "email" && (
          <div className="flex w-full h-fit justify-between gap-5">
            <ShowImage image={expiry_image} setImage={setImageTemplate} />
            <ImageInput
              imageName={"Image Template"}
              setImage={setImageTemplate}
            />
          </div>
        )}
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthCardsRenewalAlert;
