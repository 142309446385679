import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { About, Categories, HealthCards, Login, HealthCardProfiles } from './pages';
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
      <Route index={true} element={<HealthCards/>}/>
      <Route path='/profiles' element={<HealthCardProfiles />}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/categories' element={<Categories/>}/>
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
