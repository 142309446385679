import { axiosAuthorized } from "../../api/config"

export const getHealthCards = async () => {
  try {
    const response = await axiosAuthorized.get(`/gethealthcard`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getHealthCardsProfiles = async () => {
  try {
    const response = await axiosAuthorized.get(`/getcardpurchase`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTransactions = async (userId) => {
  try {
    const response = await axiosAuthorized.get(`/getusertransaction/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const sentSingleRenewal = async (initialState, expiry_message, expiry_image) => {
  try {
    const requestData = {
      ...initialState,
      expiry_message,
      expiry_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = `/renewalcard/create_renewalcard`;

    const response = await axiosAuthorized['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}



export const addHealthCard = async (initialState,category, bullent_points, health_card_image,hide, id = null) => {
  try {
    category = category?.map(item => item?._id);
    const requestData = {
      ...initialState,
      category,
      hide,
      bullent_points,
      health_card_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/healthcard/update_healthcard/${id}`
      : `/healthcard/create_healthcard`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteHealthCard = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/healthcard/delete_healthcard/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}