import React from "react";
import AboutMainSection from "../../components/AboutComponents/AboutMainSection";

const About = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
      <AboutMainSection/>
    </div>
  );
};

export default About;
